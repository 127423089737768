// Admin.js
import React, { useEffect, useMemo, useState } from "react";
import { useFetch } from "../../hooks.js/useFetch";
import Styles from "./Admin.module.scss";
import { NavLink, Route, Routes, useNavigate } from "react-router-dom";
import { LoadingSpinner } from "../../elements/loadingSpinner/loadingSpinner";
import PhotoAttachments from "../../components/Admin/PhotoAttachments";
import Consultations from "../../components/Admin/Consultations";
import jsonData from "../../../package.json";

const Admin = ({ setLoggedIn, login, password }) => {
    const currentProtocol = window.location.protocol;
    const body = useMemo(() => ({ login, password }), []);
    const { loading, response, error } = useFetch({
        url: `${currentProtocol}//${process.env.REACT_APP_SERVER_URL}forms/`,
        method: "post",
        body,
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (!response) return;
        navigate({
            pathname: "photos",
        });
    }, [response]);

    useEffect(() => {
        if (!error) return;
        setLoggedIn(false);
    }, [error, setLoggedIn]);

    return (
        <div className={Styles.container}>
            <div className={Styles.header}>
                <NavLink activeClassName={Styles.active} to="photos">
                    Формы с фото
                </NavLink>
                <NavLink activeClassName={Styles.active} to="consultations">
                    Формы с консультациями
                </NavLink>
                <NavLink activeClassName={Styles.active} to="change-title">
                    Изменить заголовок
                </NavLink>
            </div>
            {loading && (
                <div className={Styles.spinnerBox}>
                    <LoadingSpinner />
                </div>
            )}
            <Routes>
                <Route
                    path="/photos"
                    element={<PhotoAttachments forms={response?.forms} />}
                />
                <Route
                    path="/consultations"
                    element={<Consultations forms={response?.forms} />}
                />
                <Route path="/change-title" element={<ChangeTitlePage />} />
            </Routes>
        </div>
    );
};

const ChangeTitlePage = () => {
    const [newMainTitle, setNewMainTitle] = useState('');

    const handleMainTitleChange = () => {
        // Проверка, что jsonData - это объект
        if (typeof jsonData === 'object' && jsonData !== null) {
            jsonData.mainTitle = newMainTitle;
            setNewMainTitle('');
            console.log('Обновленный mainTitle:', jsonData.mainTitle);
        } else {
            console.error('jsonData не является объектом:', jsonData);
        }
    };

    return (
        <div>
            <label htmlFor="newMainTitle">Новый заголовок для главной страницы:</label>
            <input
                type="text"
                id="newMainTitle"
                value={newMainTitle}
                onChange={(e) => setNewMainTitle(e.target.value)}
            />
            <button onClick={handleMainTitleChange}>Изменить заголовок</button>
        </div>
    );
};

export default Admin;
