import React, { useEffect, useState } from 'react';
import { useDropDownMessageContext } from "../../ContextProviders/DropDownMessageProvider";
import WidthProvider from "../../ContextProviders/WidthProvider";
import Company from "../../components/Company/Company";
import ContactUs from "../../components/ContactUs/ContactUs";
import FAQ from "../../components/FAQ/FAQ";
import Footer from "../../components/Footer/Footer";
import Geography from "../../components/Geography/Geography";
import Header from "../../components/Header/Header";
import MainInfo from "../../components/MainInfo/MainInfo";
import Portfolio from "../../components/Portfolio/Portfolio";
import PriceSheet from "../../components/PriceSheet/PriceSheet";
import Services from "../../components/Services/Services";
import WorkingStages from "../../components/WorkingStages/WorkingStages";
import { DropDownMessage } from "../../elements/dropDownMessage/dropDownMessage";
import { useOpenConsultation } from "../../hooks/useOpenConsultation";
import jsonData from "../../../package.json";

const Main = () => {
    const { isShown } = useDropDownMessageContext();
    const { open: isConsultationOpen, setOpen: setIsConsultationOpen } = useOpenConsultation();
    const [mainTitle, setMainTitle] = useState(jsonData.mainTitle);

    useEffect(() => {
        // Обновляем заголовок при изменении внешних данных
        setMainTitle(jsonData.mainTitle);
    }, [jsonData.mainTitle]);


    return (
        <div style={{ overflowX: 'hidden' }}>
            <WidthProvider>
                {isShown && <DropDownMessage />}
                <Header />
                <MainInfo
                    isDescriptionNeeded={true}
                />

                <Services
                  cityContext={undefined}
                  isConsultationOpen={isConsultationOpen}
                  setIsConsultationOpen={setIsConsultationOpen}
               />
               <WorkingStages />
               <Geography />
               <Portfolio />
               <PriceSheet
                  setIsConsultationOpen={setIsConsultationOpen}
               />
               <FAQ />
               <Company />
               <ContactUs />
               <Footer />

         </WidthProvider>
         </div>
   )
}

export default Main